<template>
  <v-textarea
    :id="id"
    ref="input-text-area"
    :label="label"
    :loading="loading"
    :disabled="disabled"
    :hide-details="hideDetails"
    outlined
    :rows="rows"
    :rules="rules"
    class="pt-0"
    :class="{ 'mt-3': !dense }"
    v-model="textareainput"
    :placeholder="placeholder"
    v-on:keypress="(e) => manageLimit(e)"
    v-on:paste="(e) => onPaste(e)"
  ></v-textarea>
</template>
<script>
export default {
  name: "textarea-input",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    rows: {
      type: Number,
      default: 3,
    },
    rules: {
      type: [Array, Object],
      default: () => {
        return [];
      },
    },
    label: {
      type: String,
      default: null,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      textareainput: null,
    };
  },
  watch: {
    value() {
      this.textareainput = this.value;
    },
    textareainput() {
      this.$emit("input", this.textareainput);
    },
  },
  methods: {
    manageLimit(e) {
      if (this.textareainput && this.textareainput.length > 199) {
        e.preventDefault();
      }
    },
    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.textareainput;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 199) {
        let trimValue = finalval.substring(0, 200);
        this.textareainput = trimValue;
        e.preventDefault();
      }
    },
  },
  mounted() {
    this.textareainput = this.value;
  },
};
</script>
